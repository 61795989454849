.App-header{
  padding-left: 50px;
}

label{
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

textarea{
  width: 50%;
  height: 100px;
  overflow-y: auto;
  font-size: 16px;
}

button{
  width: 120px;
  height: 38px;
  margin: 12px 0px;
}

input{
  width: 50%;
  height: 100px;
  overflow-y: auto;
}